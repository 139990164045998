.custom-react-calendar {
  background-color: #fff!important;
}

.react-calendar__tile--now {
  background-color: #f5f5f5!important;
  border-radius: 50px;
}

.react-calendar {
  border: 1px solid #4496ff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  border-radius: 10px;
}

.react-react-calendar__navigation__arrow {
  border-radius: 10px;
}
.react-calendar__navigation button:enabled:hover {
  border-radius: 10px;
  background-color: #4496ff;
  color: #fff;
}

.react-calendar__navigation button:disabled {
  border-radius: 10px;
  display: none
}